import { Link, scroller } from "react-scroll";
import { useState, useRef, useEffect } from "react";
import { useOnClickOutside } from "../components/PlayModeAnimation";
import opticalImage from "styles/assets/header/optical.webp";
import monsterrWorldBg from "./header/monsterra-world-bg.webp";
import ambassadorBg from "./header/ambassador-bg.webp";
import partnerBg from "./header/partner-ship-bg.webp";
import focus from "./header/focus.webp";
import startBg from "./header/start-bg.webp";
import startBtn from "./header/start-btn.webp";
import followPlay from "./header/follow-play.webp";
import followDao from "./header/follow-dao.webp";
import followMarket from "./header/follow-market.webp";
import followGuild from "./header/follow-guild.webp";
import followPortal from "./header/follow-portal.webp";
import followBridge from "./header/follow-bridge.webp";
import followMonstv from "./header/follow-monstv.webp";
import followEvent from "./header/follow-events.webp";
import fx from "./header/fx.webp";
var Scroll = require("react-scroll");
var Events = Scroll.Events;
function MenuItem({ title, scrollTo, isHead, linkTo }) {
  function content() {
    return (
      <div
        className={`hover-container relative h-[29px] w-full ${
          isHead && "pb-[40px]"
        }`}
      >
        <div className="px-[14px] show-on-hover h-full w-full">
          <img className="w-full h-full" src={focus} />
        </div>
        {isHead ? (
          <p className="text-[#ffd862] text-[17px] w-full text-base font-normal cursor-pointer react-button absolute top-0 left-0 text-center text-white-on-hover opacity-0">
            {title}
          </p>
        ) : (
          <p className="text-[#ffd862] text-[14px] w-full text-base font-normal cursor-pointer react-button absolute top-0 left-0 text-center text-white-on-hover">
            {title}
          </p>
        )}
      </div>
    );
  }
  if (scrollTo) {
    return (
      <Link
        href="/"
        to={scrollTo}
        spy={true}
        smooth={true}
        duration={500}
        onSetActive={() => {}}
        className="relative hover-container"
      >
        {content()}
      </Link>
    );
  }
  return (
    <a href={linkTo} className="relative hover-container">
      {content()}
    </a>
  );
}
function MenuBigItem({ title, cap, icon, replaceIcon, disabled, linkTo }) {
  let options = {};
  if (!options.href) {
    options.href = "/";
  }
  if (linkTo) {
    options.href = linkTo;
    options.target = "_blank";
  }
  return (
    <a {...options}>
      <div
        className={`hover-container relative h-[50px] lg:h-[80px] w-full p-1 cursor-pointer ${
          disabled && "grayscale"
        }`}
      >
        <div className="show-on-hover h-full w-full">
          <img className="w-full h-full" src={fx} />
        </div>
        <div className="flex absolute top-2 left-2 w-full gap-2 items-center">
          <div className="w-8 lg:w-14">
            {replaceIcon || (
              <img
                src={icon}
                className="h-8 lg:h-12 w-8 lg:w-12 object-contain flex-shrink-0"
              />
            )}
          </div>

          <div className="text-left">
            <p className="text-[#ffd862] text-[14px] w-full text-base font-normal cursor-pointer react-button text-left text-white-on-hover mb-1">
              {title}
            </p>
            <span className="hidden lg:block">{cap}</span>
          </div>
        </div>
      </div>
    </a>
  );
}
function MenuLargeItem({ title, cap, icon, replaceIcon, disabled, linkTo }) {
  let options = {};
  if (!options.href) {
    options.href = "/";
  }
  if (linkTo) {
    options.href = linkTo;
    options.target = "_blank";
  }
  return (
    <a {...options}>
      <div
        className={`hover-container relative h-[60px] lg:h-[100px] w-full p-1 cursor-pointer ${
          disabled && "grayscale"
        }`}
      >
        <div className="show-on-hover h-full w-full">
          <img className="w-full h-full" src={fx} />
        </div>
        <div className="flex absolute top-2 left-2 w-full gap-2 items-center">
          <img
            src={icon}
            className="h-10 lg:h-20 w-10 lg:w-20 object-contain flex-shrink-0"
          />

          <div className="text-left">
            <p className="text-[#ffd862] text-[18px] lg:text-[24px] w-full text-base font-normal cursor-pointer react-button text-left text-white-on-hover mb-1">
              {title}
            </p>
            <span className="hidden lg:block">{cap}</span>
          </div>
        </div>
      </div>
    </a>
  );
}
export default function Header(props) {
  const [comingsoon, setComingsoon] = useState(false);
  const [optical, setOptical] = useState(0);
  const [elements, setElements] = useState([]);
  const [scrolling, setScrolling] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showInoPopup, setShowInoPopup] = useState(false);
  const refMenuContent = useRef();
  const [meta, setMeta] = useState(null);
  useEffect(() => {
    async function load() {
      let rs = await fetch("https://api.monsterra.io/customer/get-SSO-meta");
      let data = await rs.json();
      setMeta(data);
    }
    load();
  }, []);
  const clickModal = () => {
    setComingsoon(!comingsoon);
  };
  function changeOptical(index) {
    if (scrolling) {
      return;
    }
    setOptical(index);
  }
  useEffect(() => {
    if (!window.location.hash) {
    }
    setTimeout(() => {
      let hash = window.location.hash.replace("#", "");
      scroller.scrollTo(hash, { spy: true, smooth: true, duration: 500 });
    }, 1000);
  }, [window.location.hash]);
  useEffect(() => {
    Events.scrollEvent.register("begin", function (to, element) {
      setScrolling(true);
    });

    Events.scrollEvent.register("end", function (to, element) {
      setScrolling(false);
    });
    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };
  }, []);
  useOnClickOutside(refMenuContent, () => {
    setTimeout(() => {
      setShowMenu(false);
    }, 200);
  });
  // "monsterra-world" | "ambassador" | "partner-ship" | "get-start"
  function hoverContent(type) {
    switch (type) {
      case "monsterra-world":
        return (
          <div className="relative w-full h-full">
            <img src={monsterrWorldBg} className="w-full" />
            <div className="absolute top-[5px] left-0 w-full">
              <MenuItem title="Monsterra World" scrollTo={"banner"} isHead />
              <MenuItem title="Land" scrollTo={"land"} />
              <MenuItem title="Gameplay" scrollTo="playmode" />
              <MenuItem title="MSTR Token" scrollTo="tokenomic" />
              <MenuItem
                title="Whitepaper"
                linkTo="https://whitepaper.monsterra.io/market-overview/nfts-at-a-glance"
              />
              <MenuItem title="Roadmap" scrollTo="roadmap" />
            </div>
          </div>
        );
      case "ambassador":
        return (
          <div className="relative w-full h-full">
            <img src={ambassadorBg} className="w-full" />
            <div className="absolute top-[5px] left-0 w-full px-3">
              <MenuItem
                title="Ambassador Program"
                linkTo={"/ambassador"}
                isHead
              />
              <MenuItem title="Who to join" linkTo={"/ambassador/#whotojoin"} />
              <MenuItem title="Benefits" linkTo={"/ambassador/#benifits"} />
              <MenuItem
                title="Pointing System"
                linkTo={"/ambassador/#pointingsystem"}
              />
            </div>
          </div>
        );
      case "partner-ship":
        return (
          <div className="relative w-full h-full">
            <img src={partnerBg} className="w-full" />
            <div className="absolute top-[5px] left-0 w-full px-4">
              <MenuItem
                title="Partnership Program"
                linkTo={"/partnership/"}
                isHead
              />
              <MenuItem
                title="Why partnership"
                linkTo="/partnership/#whypartnership"
              />
              <MenuItem
                title="Apply partnership"
                linkTo="/partnership/#applypartnership"
              />
              <MenuItem
                title="Official partners"
                linkTo="/partnership/#officialpartners"
              />
            </div>
          </div>
        );
      case "get-start":
        return (
          <div className="relative w-full h-full">
            <img src={startBg} className="w-full" />
            <div className="absolute top-3 left-0 w-full">
              <div className="flex flex-wrap px-4 lg:px-6  lg:py-4 items-center">
                <div className="w-1/2">
                  <MenuBigItem
                    disabled={meta?.meta?.GameLink === ""}
                    linkTo={meta?.meta?.GameLink}
                    title="Play game"
                    cap={
                      <p className="text-xs text-[#ad8e73]">Main gameplay</p>
                    }
                    icon={followPlay}
                  />
                </div>
                <div className="w-1/2">
                  <MenuBigItem
                    disabled={meta?.meta?.DAOLink === ""}
                    linkTo={meta?.meta?.DAOLink}
                    title="DAO"
                    cap={
                      <p className="text-xs text-[#ad8e73]">
                        Manage your account and
                        <br /> join the governance
                      </p>
                    }
                    icon={followDao}
                  />
                </div>
                <div className="w-1/2">
                  <MenuBigItem
                    disabled={meta?.meta?.MarketLink === ""}
                    linkTo={meta?.meta?.MarketLink}
                    title="Marketplace"
                    cap={
                      <p className="text-xs text-[#ad8e73]">
                        Buy and sell your
                        <br /> game assets
                      </p>
                    }
                    icon={followMarket}
                  />
                </div>
                <div className="w-1/2">
                  <MenuBigItem
                    disabled={meta?.meta?.GuildLink === ""}
                    linkTo={meta?.meta?.GuildLink}
                    title="Guild"
                    replaceIcon={
                      <img
                        src={followGuild}
                        className="h-10 lg:h-14 w-10 lg:w-14 object-contain flex-shrink-0"
                      />
                    }
                    cap={
                      <p className="text-xs text-[#ad8e73]">
                        Make higher benefits with your
                        <br /> friends and fellows
                      </p>
                    }
                    icon={followGuild}
                  />
                </div>
                <div className="w-1/2">
                  <MenuBigItem
                    disabled={meta?.meta?.PortalLink === ""}
                    linkTo={meta?.meta?.PortalLink}
                    title="Portal game"
                    cap={
                      <p className="text-xs text-[#ad8e73]">
                        Play minigames with
                        <br />
                        your assets
                      </p>
                    }
                    icon={followPortal}
                  />
                </div>
                <div className="w-1/2">
                  <MenuBigItem
                    disabled={meta?.meta?.BridgeLink === ""}
                    linkTo={meta?.meta?.BridgeLink}
                    title="Bridge"
                    cap={
                      <p className="text-xs text-[#ad8e73]">
                        Transfer your tokens between
                        <br /> supported chains
                      </p>
                    }
                    icon={followBridge}
                  />
                </div>
              </div>
              <div className="flex gap-2 py-2 px-6">
                <div className="w-1/2">
                  <MenuLargeItem
                    disabled={meta?.meta?.MonstvLink === ""}
                    linkTo={meta?.meta?.MonstvLink}
                    title="MonsTV"
                    cap={
                      <p className="text-[16px] text-[#ad8e73] mt-1">
                        Watch and share
                      </p>
                    }
                    icon={followMonstv}
                  />
                </div>
                <div className="w-1/2">
                  <MenuLargeItem
                    disabled={meta?.meta?.EventLink === ""}
                    linkTo={meta?.meta?.EventLink}
                    title="Events"
                    cap={
                      <p className="text-[16px] text-[#ad8e73] mt-1">
                        Join to earn
                      </p>
                    }
                    icon={followEvent}
                  />
                </div>
              </div>
            </div>
          </div>
        );
    }
  }
  return (
    <div className="flex flex-row fixed" style={{ top: "-1px", zIndex: 60 }}>
      {showInoPopup && (
        <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-black bg-opacity-50 z-[100]">
          <div className="w-[90%] max-w-[700px] relative mt-20 mb-20">
            <img
              onClick={() => {
                setShowInoPopup(false);
              }}
              src="assets/close.png"
              className="absolute top-[-20px] right-[-20px] z-20 h-12 cursor-pointer transform hover:scale-125 duration-300"
            />
            <div className="relative z-10 p-4 md:p-10 text-white">
              <p className="text-2xl md:text-4xl font-semibold text-center w-full">
                INO DEALS COMING SOON
              </p>
              <p className="mt-4 text-center">
                <span className="text-[#f6c463]">
                  Unlimited Whitelist Slots
                </span>{" "}
                to purchase 2nd Treasury Boxes in 15-minutes early sale this 22
                February 2022 <span className="text-[#f6c463]">OPEN NOW</span>{" "}
                for Grab. Don't miss this chance to get your biggest NFT
                packages for joining our game trading.
              </p>
              <div className="flex mt-4 flex-col md:flex-row">
                <div className="text-center">
                  <img
                    width="100%"
                    height="32px"
                    src="assets/ino/logo-gamefi.webp"
                    className="h-8 inline-block"
                  />
                  <a href="https://gleam.io/cOIqK/gamefi-x-monsterra-ino-whitelist-campaign">
                    <img
                      width="100%"
                      height="40px"
                      src="assets/ino/btn-get-whitelist.webp"
                      className="h-10 inline-block mt-2 react-button"
                    />
                  </a>
                </div>
                <div className="text-center mt-6 md:mt-0">
                  <img
                    width="100%"
                    height="32px"
                    src="assets/ino/logo-metaverse.webp"
                    className="h-8 inline-block"
                  />
                  <a href="https://gleam.io/GW5UT/metaverse-starter-x-monsterra-ino-whitelist-campaign">
                    <img
                      width="100%"
                      height="40px"
                      src="assets/ino/btn-get-whitelist.webp"
                      className="h-10 inline-block mt-2 react-button"
                    />
                  </a>
                </div>
              </div>
              <p className="text-center mt-4">
                Get noticed the instant updates about INO this February RIGHT
                NOW!
              </p>
              <div className="flex justify-around flex-col md:flex-row mt-6">
                <div className="text-center">
                  <a href="https://t.me/monsterra_official">
                    <img
                      width="100%"
                      height="40px"
                      src="assets/ino/btn-telegram.webp"
                      className="h-10 inline-block react-button"
                    />
                  </a>
                </div>
                <div className="text-center">
                  <a href="https://t.me/monsterra_official">
                    <img
                      width="100%"
                      height="40px"
                      src="assets/ino/market-place.png"
                      className="h-10 inline-block w-auto react-button"
                    />
                  </a>
                </div>
              </div>
            </div>
            <img
              width="100%"
              height="100%"
              src="assets/ino/bg.webp"
              className="absolute top-0 left-0 w-full h-full z-0"
            />
          </div>
        </div>
      )}
      {comingsoon ? (
        <div
          id="success-popup"
          style={{ zIndex: 1000 }}
          className="fixed  top-0 left-0 w-screen h-screen flex items-center justify-center bg-black bg-opacity-50"
        >
          <div className="bg-comming_soon_bg bg-cover md:h-[500px] h-1/3 md:w-[900px] w-4/5 relative bg-center rounded-md p-6 justify-center items-center flex">
            <div
              onClick={() => clickModal()}
              className="absolute top-0 right-0 mr-3 mt-3 cursor-pointer"
            >
              <img src="/assets/closeBlack.svg" alt="monsterra" />
            </div>
          </div>
        </div>
      ) : null}
      <div className="hidden ipadpro:flex flex-row items-center justify-between gap-8 bg-headerbg bg-cover bg-center w-screen  max-w-[1440px]  text-center z-50 hd:h-24 h-20 pb-4 pl-20 pr-14 hd:px-28">
        <div className="w-[200px]">
          <Link
            href="/"
            to="banner"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            onSetActive={() => {
              if (optical !== 0) {
                changeOptical(0);
              }
            }}
            onClick={() => {
              changeOptical(0);
            }}
          >
            <img
              width="606"
              height="222"
              className="cursor-pointer"
              src="/assets/header/logo_v2.webp"
              alt="monsterra logo"
            />
          </Link>
        </div>
        <div
          ref={(ref) => {
            if (!ref) {
              return;
            }
            let els = [];
            let index = 0;
            ref.childNodes.forEach((i) => {
              if (i.localName !== "a") {
                return;
              }
              els.push({
                width: i.clientWidth,
                pos: i.offsetLeft,
              });
              index++;
              if (elements.length) {
                if (els[0].width > elements[0].width) {
                  setElements(els);
                }
              } else {
                setElements(els);
              }
            });
          }}
          className="flex flex-row text-lg font-bold hd:gap-10 gap-4 bg-clip-text items-center justify-center w-full select-none relative"
        >
          <div className="relative hover-container">
            <span className="textGradient cursor-pointer react-button">
              Monsterra World
            </span>
            <div className="absolute top-[-3px] left-[-13px] w-[171px] show-on-hover">
              {hoverContent("monsterra-world")}
            </div>
          </div>

          <div className="relative hover-container">
            <p className="textGradient cursor-pointer react-button">
              Ambassador Program
            </p>
            <div className="absolute top-[-3px] left-[-9px] w-[201px] show-on-hover">
              {hoverContent("ambassador")}
            </div>
          </div>
          <div className="relative hover-container">
            <p className="textGradient cursor-pointer react-button">
              Partnership Program
            </p>
            <div className="absolute top-[-3px] left-[-16px] w-[213px] show-on-hover">
              {hoverContent("partner-ship")}
            </div>
          </div>
          <div className="relative hover-container">
            <p className="textGradient cursor-pointer react-button text-2xl">
              Get START!
            </p>
            <div className="absolute top-[-3px] left-[-16px] w-[213px] show-on-hover">
              <img
                src={startBtn}
                className="w-[197px] top-[-5px] left-[4px] absolute"
              />
            </div>
            <div className="absolute top-[39px] right-[-59px] w-[580px] show-on-hover">
              {hoverContent("get-start")}
            </div>
          </div>
        </div>
      </div>
      <div className="flex ipadpro:hidden relative flex-row items-center justify-center w-screen h-[60px] bg-header_mb_bg bg-cover bg-center">
        <div>
          <Link
            to="banner"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            href="/"
          >
            <img
              width="167"
              height="60"
              className="cursor-pointer h-[60px] mt-2 "
              src="/assets/header/logo_v2.webp"
              alt="monsterra logo"
            />
          </Link>
        </div>
        <div className="dropdown absolute flex top-0 left-0  justify-center items-center mt-[12px]">
          <img
            width="100%"
            height="100%"
            className="ml-3"
            src="/assets/header/menu.svg"
            alt="monsterra menu"
            onClick={() => {
              if (showMenu) return;
              setShowMenu(true);
            }}
          />
          {showMenu && (
            <div
              ref={refMenuContent}
              className="dropdown-content w-screen max-w-[360px] mt-[16px]"
              style={{
                backgroundColor: "rgba(20, 20, 20, 0.85)",
                minWidth: 360,
              }}
            >
              <div className=" flex flex-col items-center">
                <div className="flex flex-col text-lg font-bold gap-2 w-full  select-none ">
                  <p className="bg-clip-text text-transparent bg-gradient-to-b from-tab1 via-tab2 to-tab1 cursor-pointer">
                    Monsterra World
                  </p>
                  <div
                    className="h-[1px] w-full "
                    style={{ backgroundColor: "rgba(65,59,27,1)" }}
                  />
                  <a href="/ambassador">
                    <p className="bg-clip-text text-transparent bg-gradient-to-b from-tab1 via-tab2 to-tab1 cursor-pointer">
                      Ambassador Program
                    </p>
                  </a>
                  <div
                    className="h-[1px] w-full "
                    style={{ backgroundColor: "rgba(65,59,27,1)" }}
                  />
                  <a href="/partnership">
                    <p className="bg-clip-text text-transparent bg-gradient-to-b from-tab1 via-tab2 to-tab1 cursor-pointer">
                      Partnership Program
                    </p>
                  </a>
                  <div
                    className="h-[1px] w-full "
                    style={{ backgroundColor: "rgba(65,59,27,1)" }}
                  />
                  <p className="bg-clip-text text-transparent bg-gradient-to-b from-tab1 via-tab2 to-tab1 cursor-pointer">
                    Get START!
                  </p>
                  <div className="">{hoverContent("get-start")}</div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* <div className="ipadpro:hidden fixed bottom-0 w-full left-0 text-center">
        <div
          className="bg-cover mx-auto inline-block py-2 p-1"
          style={{ backgroundImage: `url(/assets/bottom-social.webp)` }}
        >
          <div className="flex mx-auto  w-full">
            <img
              onClick={() =>
                window
                  .open(" https://twitter.com/Monsterra_P2E ", "_blank")
                  .focus()
              }
              className="cursor-pointer mt-3 w-full"
              src="/assets/footer/Twitter.webp"
              alt="Twitter"
            />
            <img
              onClick={() =>
                window
                  .open("https://t.me/monsterragame_chat ", "_blank")
                  .focus()
              }
              className="cursor-pointer mt-3 w-full"
              src="/assets/footer/Telegram.webp"
              alt="Telegram"
            />
            <img
              onClick={() =>
                window.open("https://discord.gg/R5uG9WCyr7", "_blank").focus()
              }
              className="cursor-pointer  mt-3 w-full"
              src="/assets/footer/Discord.webp"
              alt="Discord"
            />
            <img
              onClick={() =>
                window
                  .open(
                    "https://www.facebook.com/Monsterra-P2E-Game-100591379141319",
                    "_blank"
                  )
                  .focus()
              }
              className="cursor-pointer  mt-3 w-full"
              src="/assets/footer/Facebook.webp"
              alt="Facebook"
            />
            <img
              onClick={() =>
                window
                  .open("https://medium.com/@monsterra.io", "_blank")
                  .focus()
              }
              className="cursor-pointer mt-3 w-full"
              src="/assets/footer/medium.webp"
              alt="Medium"
            />
            <img
              onClick={() =>
                window
                  .open("https://www.youtube.com/MonsterraNFTGame", "_blank")
                  .focus()
              }
              className="cursor-pointer mt-3 w-full"
              src="/assets/footer/youtube.webp"
              alt="Youtube"
            />
            <img
              onClick={() =>
                window
                  .open("https://www.tiktok.com/@monsterra_nftgame", "_blank")
                  .focus()
              }
              className="cursor-pointer mt-3 w-full"
              src="/assets/footer/Tiktok.webp"
              alt="Medium"
            />
          </div>
        </div>
      </div> */}
    </div>
  );
}
