import { useState } from "react";

export default function Footer(props) {
  const [isModal, setIsModal] = useState(false);
  const [email, setEmail] = useState("");

  const clickModal = () => {
    setIsModal(!isModal);
  };

  const register = async () => {
    if (email.length !== 0) {
      await fetch("https://sub.monsterra.io/subscriber/subscribe", {
        method: "POST",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mail: email,
          content_id: 1,
          meta: {},
        }),
      });
      setEmail("");
      clickModal();
    }
  };

  return (
    <div className="flex flex-col justify-center items-center w-full relative bg-footerBG select-none text-center ipadpro:pb-0">
      {/* {isModal ? (
        <div
          id="success-popup"
          className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-50 bg-black bg-opacity-50"
        >
          <div className=" rounded-md border-[#cd9f77] border-4 bg-white p-6">
            <p className="text-2xl nasa text-heavyTint text-center">
              Successfully
            </p>
            <p className="text-center mt-2">
              Thank you for subcribing to our Monsterra Newsletter.
            </p>
            <p className="text-center ">Stay tuned for more updates!</p>
            <p
              id="close-success-popup"
              className="text-2xl font-bold cursor-pointer opacity-70 hover:opacity-100 text-center mt-4"
              onClick={() => clickModal()}
            >
              Close
            </p>
          </div>
        </div>
      ) : null} */}
      <div className="absolute top-0">
        <img
          className="h-3"
          src="/assets/line.webp"
          alt="monsterra"
          width="882"
          height="13px"
        />
      </div>
      <div className="absolute bottom-0">
        <img
          className="h-3"
          src="/assets/line.webp"
          alt="monsterra"
          width="882"
          height="13px"
        />
      </div>
      <div className="absolute top-[-35px] ">
        <img
          className="w-[150px]"
          src="/assets/footer/logo_v2.webp"
          alt="monsterra logo"
          width="606"
          height="222"
        />
      </div>
      {/* <p className="text-2xl text-greyBlur2 px-4 pt-28">
        Don't miss out, get early access to our token whitelist!
      </p>
      <div className="flex flex-row justify-center px-2 w-full gap-1 mt-11">
        <div className=" px-4 py-2 bg-brown2" onClick={() => register()}>
          <p className="font-bold text-xl text-brown1 cursor-pointer  react-button">
            REGISTER
          </p>
        </div>
        <div className="px-4 py-2 bg-brown3 border border-brown2 ">
          <input
            placeholder="YOUR EMAIL ADDRESS"
            className="w-full md:w-80 bg-transparent outline-none"
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
        </div>
      </div> */}
      {/* <p className=" text-base text-greyBlur1 mt-3 px-4 mt-20">
        Subscribe our channels to update latest news of Monsterra Events.
      </p> */}
      {/* <div className="flex flex-wrap justify-center md:text-left px-10 flex-col md:flex-row">
        <div className="flex flex-col mt-20 md:mr-10">
          <p className="text-white text-2xl font-bold">Keep in touch</p>
          <a href="https://blogs.monsterra.io/" target="_blank">
            <p className=" text-lg text-greyBlur1 cursor-pointer react-text mt-3">
              News
            </p>
          </a>

          <p
            onClick={() =>
              window
                .open(
                  "https://whitepaper.monsterra.io/official-channels/support-pages",
                  "_blank"
                )
                .focus()
            }
            className=" text-lg text-greyBlur1 cursor-pointer react-text mt-3"
          >
            Support
          </p>
        </div>
        <div className="flex flex-col mt-20 md:mr-10">
          <p className="text-white text-2xl font-bold ">Monsterra World</p>
          <p
            onClick={() =>
              window
                .open(
                  "https://www.youtube.com/channel/UCjTCP6BkSrPS5bDs0JkJBjg",
                  "_blank"
                )
                .focus()
            }
            className=" text-lg text-greyBlur1 cursor-pointer react-text mt-3"
          >
            Media
          </p>
          <p
            onClick={() =>
              window.open("https://play.monsterra.io/", "_blank").focus()
            }
            className=" text-lg text-greyBlur1 cursor-pointer react-text mt-3"
          >
            Play Game
          </p>
          <p
            onClick={() =>
              window.open("https://marketplace.monsterra.io/", "_blank").focus()
            }
            className=" text-lg text-greyBlur1 cursor-pointer react-text mt-3"
          >
            Marketplace
          </p>
        </div>
        <div className="flex flex-col  mt-20">
          <p className="text-white text-2xl font-bold ">Policy</p>
          <p
            onClick={() =>
              window
                .open(
                  "https://whitepaper.monsterra.io/policy/term-of-use",
                  "_blank"
                )
                .focus()
            }
            className=" text-lg text-greyBlur1 cursor-pointer react-text mt-3"
          >
            Term of Use
          </p>
          <p
            onClick={() =>
              window
                .open(
                  "https://whitepaper.monsterra.io/policy/disclaimer",
                  "_blank"
                )
                .focus()
            }
            className=" text-lg text-greyBlur1 cursor-pointer react-text mt-3"
          >
            Disclaimer
          </p>
          <p
            onClick={() =>
              window
                .open(
                  "https://whitepaper.monsterra.io/policy/private-policy",
                  "_blank"
                )
                .focus()
            }
            className=" text-lg text-greyBlur1 cursor-pointer react-text mt-3"
          >
            Privacy Policy
          </p>
        </div>
      </div> */}
      <div className="flex-row mt-11 flex">
        <img
          width="100%"
          height="100%"
          onClick={() =>
            window.open(" https://twitter.com/Monsterra_P2E ", "_blank").focus()
          }
          className="cursor-pointer react-button mt-3 mr-3"
          src="/assets/footer/Twitter.webp"
          alt="Twitter"
        />
        <img
          onClick={() =>
            window.open("https://t.me/monsterra_official", "_blank").focus()
          }
          className="cursor-pointer react-button mt-3 mr-3"
          src="/assets/footer/Telegram.webp"
          alt="Telegram"
          width="100%"
          height="100%"
        />
        {/* <img
          onClick={() =>
            window.open("https://discord.gg/R5uG9WCyr7", "_blank").focus()
          }
          className="cursor-pointer react-button mt-3 mr-3"
          src="/assets/footer/Discord.webp"
          alt="Discord"
        /> */}
        <img
          width="100%"
          height="100%"
          onClick={() =>
            window
              .open(
                "https://www.facebook.com/Monsterra-P2E-Game-100591379141319",
                "_blank"
              )
              .focus()
          }
          className="cursor-pointer react-button mt-3 mr-3"
          src="/assets/footer/Facebook.webp"
          alt="Facebook"
        />
        <img
          width="100%"
          height="100%"
          onClick={() =>
            window.open("https://medium.com/@monsterra.io", "_blank").focus()
          }
          className="cursor-pointer react-button mt-3 mr-3"
          src="/assets/footer/medium.webp"
          alt="Medium"
        />
        <img
          width="100%"
          height="100%"
          onClick={() =>
            window
              .open("https://www.youtube.com/MonsterraNFTGame", "_blank")
              .focus()
          }
          className="cursor-pointer react-button mt-3 mr-3"
          src="/assets/footer/youtube.webp"
          alt="Youtube"
        />
        <img
          width="100%"
          height="100%"
          onClick={() =>
            window
              .open("https://www.tiktok.com/@monsterra_nftgame", "_blank")
              .focus()
          }
          className="cursor-pointer react-button mt-3 mr-3"
          src="/assets/footer/Tiktok.webp"
          alt="Tiktok"
        />
      </div>
      <p className=" text-lg mt-6 mb-4 react-text">
        CRESCENT SHINE PTE LTD © 2022, ALL RIGHTS RESERVED.
      </p>
    </div>
  );
}
