import { useState, useEffect } from "react";
import Header from "layout/Header";
import Social from "layout/Social";
import "./index.css";
import Main from "layout/Main";
import Footer from "layout/Footer";
import Loading from "./components/Loading";
import ReactGA from "react-ga";
import Tutorial from "components/Tutorial";
ReactGA.initialize("UA-214554139-1");
const MAX_LOADING = 6;
let currentLoad = 0;
let time = null;
//6 animations
function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  const [percent, setPercent] = useState(0);
  useEffect(() => {
    let rnd = Math.floor(Math.random() * 7);
    localStorage.setItem("secret", rnd);
  }, []);
  const [story, setStory] = useState(false);
  function increaseLoading() {
    currentLoad++;
  }
  useEffect(() => {
    time = setInterval(() => {
      let percent = currentLoad / MAX_LOADING;
      setPercent(percent);
      if (percent === 1) {
        clearInterval(time);
        time = null;
      }
    }, 500);
  }, []);
  return (
    <div className="w-full h-full bg-[#2d1f14]">
      <img
        src="wallet.webp"
        style={{ height: "100vh", width: "auto", zIndex: 0 }}
        className="absolute object-cover"
      />

      {/* {percent < 1 && <Loading percent={percent} />} */}
      <div className="flex flex-col mx-auto overflow-x-hidden">
        <div style={{ height: "100vh" }}></div>
        {/* <div className="w-full flex justify-center">
          <Header
            onScrollStory={(sr) => {
              setStory(sr);
            }}
          />
        </div>
        <div className="w-full flex justify-center">
          <Social />
        </div> */}
        {/* <Main increaseLoading={increaseLoading} showStory={story} /> */}
        <div className="relative z-10">
          <a
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.crescentshine.monswallet"
            className=" absolute cursor-pointer"
            style={{ width: "14%", height: "8%", right: "20%", bottom: "12%" }}
          ></a>
          <a
            target="_blank"
            href="https://apps.apple.com/app/monswallet/id1672932995"
            className="absolute cursor-pointer"
            style={{ width: "14%", height: "7%", right: "20%", bottom: "4%" }}
          ></a>
          <div className="w-full">
            <Footer />
          </div>
        </div>

        {/* <Tutorial /> */}
      </div>
    </div>
  );
}

export default App;
